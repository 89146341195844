import React from 'react';
import IPage from '../../interface/page';
import { Project } from './ui-elements';
import { MStarsData, NSCData, RevelryData } from '../../config/projectData';
import { motion } from 'framer-motion';
import { fadeTransition, fadeVariant } from '../../config/transition';

const Home: React.FunctionComponent<IPage> = props => {
  return (
    <motion.div animate="in" initial="out" exit="out" variants={fadeVariant} transition={fadeTransition}>
      <div className="header-block container flex flex-col items-center justify-center space-y-2 pt-10 pb-5">
        <p className="text-sm font-sansSerif md:text-base text-soot">
          Full-stack developer and writer from Brooklyn (fka <a href="https://www.bklynlibrary.org/blog/2019/11/08/native-land" target="_blank" rel="noreferrer">Lenape</a>).
        </p>
      </div>

      {/* Projects */}
      <div className="project-container">
        <h2 className="container text-base font-mono text-berry py-6 uppercase">Selected Projects</h2>
        <Project data={MStarsData} open={true}/>
        <Project data={RevelryData} open={false} />
        <Project data={NSCData} open={false}/>
        {/* <Project data={MeshData} open={false}/>
        <Project data={HearthData} open={false} /> */}
      </div>

      {/* Contact */}
      <div className="contact-block flex flex-col justify-center items-center border-t border-berry py-8 md:py-16 space-y-1 md:space-y-4">
        <h3 className="text-sm md:text-base font-sansSerif tracking-wide text-soot">Want to work together?</h3>
        <a href="mailto:work@yillachen.com" className=" text-md md:text-lg font-serif tracking-wide text-berry">
          Send me an email
        </a>
      </div>
    </motion.div>
  );
};

export default Home;
